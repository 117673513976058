@import '../../node_modules/bootstrap/scss/_functions';
@import 'theme/functions';

@import '../../node_modules/bootstrap/scss/mixins';
@import 'theme/mixins';

@import '_user-variables.scss';
@import 'theme/variables';
@import '../../node_modules/bootstrap/scss/variables';
@import 'flags.scss';

//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

//Change font to Roboto
$font-family-sans-serif: "Roboto", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;

body {
  font-family: $font-family-sans-serif;
}

input,
button,
select,
optgroup,
textarea,
label,
.alert,
.badge,
.blockquote-footer,
.btn,
.navbar,
.pagination,
.valid-feedback,
.invalid-feedback {
  font-family: $font-family-sans-serif;
}

.form-control {
  font-weight: unset !important;
}

.htmx-request {
  color: var(--phoenix-btn-disabled-color);
  pointer-events: none;
  background-color: var(--phoenix-btn-disabled-bg);
  border-color: var(--phoenix-btn-disabled-border-color);
  opacity: var(--phoenix-btn-disabled-opacity);
  box-shadow: none;
}

.text-border {
  display: flex;
  flex-direction: row;
  justify-content: center;
  letter-spacing: 0.125rem;
  padding: 1rem 0 .5rem;

  &:before, &:after {
    background-color: $gray-500;
    opacity: 0.3;
    content: '\a0';
    flex-grow: 1;
    height: 0.08rem;
    position: relative;
    top: 0.5em;
  }

  &:before {
    margin-right: .9375rem;
  }

  &:after {
    margin-left: .625rem;
  }
}

.headerstyle1 {
  color: $gray-600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  letter-spacing: 0.125rem;
  padding: 1rem 0 .5rem;
  text-align: center;
  text-transform: uppercase;

  &:before, &:after {
    background-color: $gray-300;
    content: '\a0';
    flex-grow: 1;
    height: 0.125rem;
    position: relative;
    top: 0.5em;
  }

  &:before {
    margin-right: .9375rem;
  }

  &:after {
    margin-left: .625rem;
  }
}

.bg-yag {
  background-attachment: fixed;
  background-image: url(/Content/images/body-bg-updated.png) !important;
}

.bg-spotlight {
  background: rgb(2,0,36);
  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(78,86,113,1) 0%, rgba(49,55,74,1) 86%);
}

.choices {
  margin-bottom: 0 !important;
}

.break-spaces {
  white-space: break-spaces;
}

.text-gradient-1 {
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 48%, #FFCC70 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.text-gradient-2 {
  background: #76b852; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #8DC26F, #76b852); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #8DC26F, #76b852); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-3 {
  background-color: #85FFBD;
  background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form-label {
  text-transform: initial !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  padding-left: initial !important
}

.groupedlistheader {
  font-weight: 700 !important;
  text-align: center !important;
  padding-top: 0.5rem !important;
}

.vh-100 {
  height: 100vh !important;
}

.validation-summary-valid {
  display: none;
}

.validation-summary-errors ul {
  margin-bottom: 0 !important;
}

.accordion-button:not(.collapsed)::before {
  background-image: var(--phoenix-accordion-btn-active-icon);
  transform: var(--phoenix-accordion-btn-icon-transform);
}

.accordion-button::before {
  flex-shrink: 0;
  width: var(--phoenix-accordion-btn-icon-width);
  height: var(--phoenix-accordion-btn-icon-width);
  content: "";
  background-image: var(--phoenix-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--phoenix-accordion-btn-icon-width);
  transition: var(--phoenix-accordion-btn-icon-transition);
}

.accordion-button:not(.collapsed)::after {
  display: none;
}

.accordion-button::after {
  display: none;
}

dl dd:nth-of-type(2n+2), dl dt:nth-of-type(2n+2) {
}

.authorise-anon, .authorise-jobseeker, .authorise-employer, .authorise-auth, .authorise-admin {
  display: none;
}

.spinner-border-xs {
  --phoenix-spinner-width: 0.8rem;
  --phoenix-spinner-height: 0.8rem;
  --phoenix-spinner-border-width: 0.2em;
}

.hover-appear:hover {
  opacity: 0.7;
  cursor: pointer;
}

.hover-appear {
  opacity: 0;
}

.above-modal {
  z-index: 1060;
}

.w-33 {
  width: 33% !important;
}

.choices__item {
  white-space: nowrap !important;
  overflow: hidden;
}

.h-1 {
  height: 1px !important;
}

.sticky-feedback-button {
  position: fixed;
  right: -45px;
  top: 50%;
  z-index: 1000;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}

#googlepay-button-container, #applepay-button-container {
  width: 100% !important;
  height: 55px !important;
}

apple-pay-button {
  --apple-pay-button-width: 100% !important;
  --apple-pay-button-height: 55px !important;
}

.choices .choices__list--multiple .choices__item {
  font-size: 0.8rem !important;
}

.choices .choices__list--single .choices__button {
  background-color: rgb(248 248 248) !important;
}

#PreferredLocations li:first-child .preferredlocationremove-container {
  display: none;
}

#ViewPermissionControls .choices__inner {
  border: none;
}

@include media-breakpoint-down(md) {
  #navbar-top-search-box .dropdown-menu {
    margin-top: 1em !important;
    position: fixed !important;
    left: 0.5em !important;
    right: 0.5em !important;
  }

  #navbar-top-search-box .dropdown-menu h6 {
    white-space: normal !important;
  }
}

.bg-none {
  background: none !important;
}

.theme-control-toggle .theme-control-toggle-label {
  background: none !important;
  color: var(--phoenix-nav-link-color);
}

.theme-control-toggle .theme-control-toggle-label:hover {
  color: var(--phoenix-nav-link-color);
}

.featuread, .featuread a, .businessad, .businessad a {
  font-size: 0.875em !important;
}

//SimpleMDE Dark Theme
.CodeMirror, .editor-preview {
  background-color: var(--phoenix-emphasis-bg) !important;
  color: var(--phoenix-body-color) !important;
}

.editor-toolbar a {
  color: var(--phoenix-tertiary-color) !important;
}

.editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background-color: unset !important;
}

//Account/Photos photo delete button
.gslide-title{
  display:none;
}

@media (max-width: 769px) {
  .photo-delete-button {
    //margin-bottom: 0 !important;
  }
}
